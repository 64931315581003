
export default {
    name: 'ElePassword',
    emits: ['update:visible'],
    props: {
        visible: Boolean
    },
    data() {
        return {
            // 表单数据
            form: {
                old: '',
                password: '',
                password2: ''
            },
            // 表单验证
            rules: {
                old: [{
                    required: true,
                    message: this.$t('请输入原登录密码'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password: [{
                    required: true,
                    message: this.$t('请输入新的登录密码'),
                    type: 'string',
                    trigger: 'blur'
                }],
                password2: [{
                    required: true,
                    validator: async (rule, value) => {
                        if (!value) {
                            return Promise.reject(this.$t('user.tips.new_password2'));
                        }
                        if (value === this.form.password) {
                            return Promise.resolve();
                        }
                        return Promise.reject(this.$t('user.tips.error_password'));
                    },
                    type: 'string',
                    trigger: 'blur'
                }]
            },
            // 按钮loading
            loading: false
        };
    },
    methods: {
        /* 保存修改 */
        onOk() {
            this.$refs.pswForm.validate().then(() => {
                this.loading = true;
                this.$http.post('/main/password', {
                    old_password: this.form.old,
                    new_password: this.form.password
                }).then(res => {
                    this.loading = false;
                    if (res.data.code === 0) {
                        this.$message.success(res.data.msg);
                        this.onUpdateVisible(false);
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.loading = false;
                    this.$message.error(e.message);
                });
            }).catch(() => {});
        },
        /* 关闭回调 */
        onCancel() {
            this.form = {};
            this.$refs.pswForm.resetFields();
            this.loading = false;
        },
        /* 修改visible */
        onUpdateVisible(value) {
            this.$emit('update:visible', value);
        }
    }
}
