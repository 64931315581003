import { io } from 'socket.io-client'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import _ from "lodash";
export default {
    install: (app, { connection, options }) => {
        const socket = io(connection, options);
        app.config.globalProperties.$socket = socket;
        socket.emit('connection');

        let modal_timer = _.debounce(() => {
            Modal.confirm({
                title: '提示',
                icon: createVNode(ExclamationCircleOutlined),
                content: '页面即将刷新升级，是否需要刷新',
                okText: "刷新",
                onOk() {
                    window.location.reload();
                },
                onCancel() { },
            })
        }, 300);

        socket.on("reload", () => {
            modal_timer();
        })

    }
}