/** 主入口js */
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './config/axios-config';
import permission from './utils/permission';
import './styles/index.less';
import EleAdminPro from 'ele-admin-pro';
import ModalUtil from 'ele-admin-pro/packages/modal-util';
import i18n from './lang';
import setting from './config/setting';
// import svgPlugin from './svgPlugin';
import docs from "./views/docs.vue";
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import Socketio from '@/plugins/Socket';
import EleProTable from "@/init/ele-pro-table"
import SelectCustomer from "@/components/SelectCustomer/index.vue"

const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$setting = setting;
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(permission);
app.use(EleAdminPro);
app.use(ModalUtil);
app.use(i18n);
app.use(EleProTable);
app.use(VMdPreview);
app.component('docs', docs);
app.component("SelectCustomer", SelectCustomer);
app.use(Socketio, {
    connection: '',
    options: {
        debug: false
    }
});

const $open = app.config.globalProperties.$message.open;

app.config.globalProperties.$message.open = (opts) => {
    if (opts.content) {
        var str_array = [];
        if (opts.type != 'error') {
            str_array = opts.content.split(',');
        } else {
            str_array = [opts.content];
        }

        opts.content = i18n.global.t(str_array[0], str_array.slice(1));
    }

    return $open(opts);
}

if (process.env.NODE_ENV !== 'production') {
    app.mount('#app');
} else {
    axios.get("/get/config", {
        params: {
            serverName: document.domain
        },
        baseURL: "/"
    }).then(res => {
        Object.assign(setting, res.data, { env: document.domain });

        if (['flashexpresscloud.com', '121.40.103.122'].includes(document.domain)) {
            setting.env = 'fhjy.lbl.morelink56.com'
        }

        document.querySelector('title').innerText = res.data.title || "主页";
        document.querySelector('link[rel*="icon"]').href = `/img/${setting.env || "morelink"}/favicon.ico`;

        app.mount('#app');
    })
}
