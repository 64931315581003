export default {
    namespaced: true,
    state: {
        code: "",
        expires_in: ""
    },
    mutations: {
        SET(state, data) {
            Object.keys(data).forEach(key => {
                state[key] = data[key];
            })
        },
        CLEAR(state, data){
            Object.keys(data).forEach(key => {
                state[key] = "";
            }) 
        }
    }
}