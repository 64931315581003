/**
 * vuex状态管理
 */
import {createStore} from 'vuex';
import getters from './getters';
import user from './modules/user';
import theme from './modules/theme';
import system from './modules/system';
import store from './modules/store';

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    theme,
    system,
    store
  },
  getters
});
