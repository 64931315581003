import { EleProTable } from "ele-admin-pro"
import $setting from "@/config/setting"
const mounted = EleProTable.mounted;
const minWidth = 576;
// const defualtHeight = 'calc(100vh - 425px)';

export default Object.assign(EleProTable, {
    props: {
        ...EleProTable.props,
        // height: {
        //     type: String || Number,
        //     default: $setting.env == 'linktrans' ? 'auto' : defualtHeight
        // },
        pageSize: {
            type: Number || String,
            default: $setting.env == 'hyd.lbl.morelink56.com' ? 15 : 15
        },
        autoHeight: {
            type: Boolean,
            default: () => true
        }
    },
    watch: {
        ...EleProTable.watch,
        "$store.state.theme.screenWidth": {
            handler(val) {
                if (!this.height) {
                    return
                }

                if (!this.autoHeight) {
                    return
                }

                if (val < minWidth) {
                    delete this.scroll.y
                } else {
                    this.scroll.y = this.rendeerHeight();
                }
                this.onRefresh();
            }
        }
    },
    mounted() {
        // old
        const hook = mounted.bind(this);
        hook();

        if (this.$store.state.theme.screenWidth < minWidth) {
            return
        }

        if (!this.autoHeight) {
            return
        }

        this.renderStyle();
    },
    methods: {
        ...EleProTable.methods,
        renderStyle() {

            this.pageSizeOptions.shift();
            this.pageSizeOptions.unshift("15");

            if (this.height) {
                this.scroll.y = this.rendeerHeight();
            }
        },
        rendeerHeight() {
            const isHavePx = (str) => {
                return str.includes('px') ? str : str + 'px'
            }

            let style = "";
            if (typeof this.height === 'string') {
                style += `${this.height.includes('calc') ? this.height : isHavePx(this.height)}`
            } else {
                style += `${this.height} px`
            }

            return style
        }
    },
    setup(props) {
        props.columns.forEach(c => {
            if (c.ellipsis || !c.width) {
                c.ellipsis = true;
                c.className += " text-ellipsis";
                c.customCell = () => {
                    return {
                        style: `max-width: ${c.width || 100}px`
                    }
                }
            }
        })
    }
});
