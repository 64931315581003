
import {
    createVNode
} from 'vue';
import {
    DownOutlined,
    MoreOutlined,
    UserOutlined,
    ForkOutlined,
    KeyOutlined,
    LogoutOutlined,
    ExclamationCircleOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    GlobalOutlined,
    InteractionOutlined
} from '@ant-design/icons-vue';
import EleCropperModal from 'ele-admin-pro/packages/ele-cropper-modal';
import {
    toggleFullscreen,
    isFullscreen
} from 'ele-admin-pro/packages/util.js';
import setting from '@/config/setting';

export default {
    name: 'EleHeaderRight',
    components: {
        DownOutlined,
        MoreOutlined,
        UserOutlined,
        ForkOutlined,
        KeyOutlined,
        LogoutOutlined,
        FullscreenOutlined,
        FullscreenExitOutlined,
        GlobalOutlined,
        InteractionOutlined,
        EleCropperModal
        // EleNotice
    },
    emits: ['item-click', 'change-language'],
    props: {
        // 是否显示打开设置抽屉按钮
        showSetting: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        // 当前登录用户信息
        loginUser() {
            return this.$store.state.user.user;
        },
        // 当前语言
        language() {
            return [this.$i18n.locale];
        },
        loginToken() {
            return JSON.parse(localStorage.getItem("token_collect"))
        },
        avatarUrl() {
            if (this.loginUser?.avatar && /^datas[\\|/]+uploads/.test(this.loginUser.avatar)) {
                return process.env.VUE_APP_STS_FILE_URL + this.loginUser.avatar
            }

            return this.loginUser.avatar
        }
    },
    data() {
        return {
            // 是否全屏状态
            fullscreen: false,
            // 修改头像
            showCropper: false,
        };
    },
    methods: {
        /* 个人信息下拉菜单点击 */
        onUserDropClick({
            key
        }) {
            if (key === 'logout') {
                // 退出登录
                this.$confirm({
                    title: this.$t('layout.logout.title'),
                    content: this.$t('layout.logout.message'),
                    icon: createVNode(ExclamationCircleOutlined),
                    maskClosable: true,
                    onOk: () => {
                        // 清除缓存的token
                        this.$store.dispatch('user/removeToken').then(() => {
                            location.replace('/login'); // 这样跳转避免再次登录重复注册动态路由
                        });

                        localStorage.removeItem("token_collect");
                    }
                });
            } else if (key === 'avatar') {
                this.showCropper = true;
            } else if (key === 'password') {
                this.$emit('item-click', 'password');
            } else if (key === 'apitoken') {
                this.$emit('item-click', 'apitoken');
            } else if (key == 'rebackServer') {
                this.$emit('item-click', 'rebackServer');
            }
        },
        /* 打开设置抽屉 */
        openSetting() {
            this.$emit('item-click', 'setting');
        },
        /* 全屏切换 */
        changeFullscreen() {
            try {
                this.fullscreen = toggleFullscreen();
            } catch (e) {
                this.$message.error('您的浏览器不支持全屏模式');
            }
        },
        /* 检查全屏状态 */
        checkFullscreen() {
            this.fullscreen = isFullscreen();
        },
        /* 切换语言 */
        changeLanguage({
            key
        }) {
            this.$emit('change-language', key);
        },
        /* 头像裁剪完成回调 */
        onCrop(res) {
            this.$http.post('/main/update_user_info', {
                avatar: res
            }).then((res1) => {
                if (res1.data.code == 0) {
                    this.showCropper = false;
                    if (setting.userUrl) {
                        this.$http.get(setting.userUrl).then((res1) => {
                            const result = setting.parseUser ? setting.parseUser(res1.data) : res1.data;
                            if (result.code === 0) {
                                const user = result.data;
                                this.loginUser.avatar = res1.data.data.avatar;
                                this.$store.dispatch('user/setUser', user);
                                this.$store.dispatch('user/setRoles', user ? user.roles : null);
                                this.$store.dispatch('user/setAuthorities', user ? user.authorities : null);
                            } else if (result.msg) {
                                this.$message.error(result.msg);
                            }
                            // 在用户权限信息请求完成后再渲染主体部分, 以免权限控制指令不生效
                            this.showContent = true;
                        }).catch((e) => {
                            console.error(e);
                            this.showContent = true;
                            this.$message.error(e.message);
                        });
                    }
                }
            })

        }
    }
}
