
import {
    QuestionCircleOutlined
} from '@ant-design/icons-vue';
import hljs from 'highlight.js/lib/core';
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';

VMdPreview.use(githubTheme, {
    Hljs: hljs
});
VMdPreview.use(createMermaidPlugin());

export default {
    name: 'docs',
    components: {
        QuestionCircleOutlined
    },
    emits: [
        'done',
        'update:visible'
    ],
    props: {
        // 文档路径
        filename: String
    },
    data() {
        return {
            // 文档内容
            docs: '',
            // 是否显示文档
            visible: false
        };
    },
    mounted() {
        this.docs = this.downloadFile(`/docs/${this.$props.filename}`);
    },
    methods: {
        /**
         * 下载文件
         */
        downloadFile(name) {
            const xhr = new XMLHttpRequest();
            const okStatus = document.location.protocol === 'file:' ? 0 : 200;

            xhr.open('GET', name, false);
            xhr.overrideMimeType('text/html;charset=utf-8');
            xhr.send(null);

            return xhr.status === okStatus ? xhr.responseText : null;
        },
        onClose() {
            this.visible = false;
        }
    }
}
