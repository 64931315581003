import axios from "axios";
import setting from "@/config/setting";

export default {
    namespaced: true,
    state: {
        config: {}
    },
    mutations: {
        SET(state, obj) {
            state.config = obj;
            setting.title = obj.title;
        }
    },
    actions: {
        getBasicConfig({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get("/admin/system_config/list/basic").then(res => {
                    if (res.data.code == 0) {
                        resolve();
                        const info = res.data.data;
                        commit('SET',Object.assign(info,{title:info.system_title}));
                    } else {
                        reject(res.data?.msg || res);
                    }
                }).catch((e) => {
                    reject(e)
                })
            })

        }
    }
}