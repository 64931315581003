
export default {
    emits: ["update:value"],
    props: {
        value: {
            type: String,
            defualt: ""
        }
    },
    data() {
        return {
            user_list: []
        }
    },
    computed: {
        text: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit("update:value", val)
            }
        }
    },
    methods: {
        /* 获取用户列表 */
        onLoadUserData() {
            this.$http.get("/admin/user/list", {
                params: {
                    limit: 999,
                    page: 1
                }
            }).then(res => {
                if (res.data.code == 0) {
                    this.user_list = res.data.data.map(user => {
                        return {
                            label: `${user.code} - ${user.account} - ${user.company_name} `,
                            value: user.code
                        }
                    });
                }
            })
        },
        /* 用户过滤器 */
        filterOption(input, option) {
            return option.value.toString().includes(input) || option.label.toString().toLocaleLowerCase().includes(input)
        }
    },
    mounted() {
        this.onLoadUserData();
    }
}
