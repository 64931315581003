/**
 * axios配置
 */
import axios from 'axios';
import store from '../store';
import router from '../router';
import setting from './setting';
import { Modal, message } from 'ant-design-vue';

// 设置统一的url
axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : "/api/private/v1";

function showReLoadingModal() {
    Modal.destroyAll();
    Modal.info({
        title: '系统提示',
        content: '登录状态已过期, 请退出重新登录!',
        okText: '重新登录',
        onOk: () => {
            goLogin(true);
        }
    });
}

/* 请求拦截器 */
axios.interceptors.request.use((config) => {
    // 添加token到header
    const token = setting.takeToken();
    if (token) {
        config.headers[setting.tokenHeaderName] = token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

/* 响应拦截器 */
axios.interceptors.response.use((res) => {
    // 登录过期处理
    if (res.data.code === 401) {
        if (res.config.url === setting.menuUrl) {
            goLogin();
        } else {
            showReLoadingModal();
        }
        return Promise.reject(new Error(res.data.msg));
    }
    // token自动续期
    const access_token = res.headers[setting.tokenHeaderName];
    if (access_token) {
        setting.cacheToken(access_token);
    }

    // 错误信息处理
    if(res.data.fields){
        let arr = res.data.fields.map(filed => `${filed.filed}: ${filed.message}\n`)
        message.error(arr.join("")) 
    }

    return res;
}, (error) => {
    // 登录过期处理
    if (error.response && error.response.status === 401) {
        showReLoadingModal();
    } else if (error.response && /50/.test(error.response.status)) {
        // router.push("/500")
    }

    return Promise.reject(error);
});

/**
 * 跳转到登录页面
 */
function goLogin(reload) {
    store.dispatch('user/removeToken').then(() => {
        localStorage.removeItem("user")
        if (reload) {
            location.replace('/login'); // 这样跳转避免再次登录重复注册动态路由
        } else {
            const path = router.currentRoute.path;
            return router.push({
                path: '/login',
                query: path && path !== '/' ? { form: path } : null
            });
        }
    });
}