/**
 * 路由配置
 */
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import setting from '@/config/setting';
import EleLayout from '@/layout';
import { menuToRoutes } from 'ele-admin-pro';
import NProgress from 'nprogress';
// import i18n from '@/lang';
// import axios from 'axios';

// const cache_lang = {};
// 静态路由
const routes = [{
    path: '/login',
    component: () =>
        import('@/views/login/login'),
    meta: { title: '登录' }
},
{
    path: '/forget',
    component: () =>
        import('@/views/login/forget'),
    meta: { title: '忘记密码' }
},
{
    path: '/register',
    component: () =>
        import('@/views/login/register'),
    meta: { title: '注册账号' }
},
{
    path: '/:pathMatch(.*)*',
    component: () =>
        import('@/views/exception/404')
},
{
    path: '/500',
    component: () =>
        import('@/views/exception/500')
},
{
    path: '/install',
    component: () =>
        import('@/views/login/install')
},
{
    path: '/admin/account/express_invoice/detail',
    component: EleLayout,
    meta: { title: 'Detail' },
    children: [{
        path: '/admin/account/express_invoice/detail',
        component: () =>
            import('@/views/admin/account/express_invoice/detail'),
        meta: {}
    }]
},
{
    path: '/admin/account/express_invoice/review',
    component: EleLayout,
    meta: { title: 'Review' },
    children: [{
        path: '/admin/account/express_invoice/review',
        component: () =>
            import('@/views/admin/account/express_invoice/review'),
        meta: {}
    }]
},
{
    path: '/admin/account/express_invoice/master_review',
    component: EleLayout,
    meta: { title: 'Master Review' },
    children: [{
        path: '/admin/account/express_invoice/master_review',
        component: () =>
            import('@/views/admin/account/express_invoice/master_review'),
        meta: {}
    }]
},
{
    path: '/user/order/claims/ups',
    component: EleLayout,
    meta: { title: 'Claims' },
    children: [{
        path: '/user/order/claims/ups',
        component: () =>
            import('@/views/user/order/claims/ups'),
        meta: {}
    }]
},
{
    path: '/user/ticket/detail/:id',
    component: EleLayout,
    meta: { title: 'DetailTicket' },
    children: [{
        path: '/user/ticket/detail/:id',
        component: () =>
            import('@/views/user/ticket/detail'),
        meta: {}
    }]
},
{
    path: '/admin/ticket/detail/:id',
    component: EleLayout,
    meta: { title: 'DetailTicket' },
    children: [{
        path: '/admin/ticket/detail/:id',
        component: () =>
            import('@/views/admin/ticket/detail'),
        meta: {}
    }]
}
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

// 路由守卫
router.beforeEach((to, from, next) => {
    NProgress.start();
    updateTitle(to);

    const handle = () => {
        if (to.path == '/500') {
            next();
        }

        // 判断是否登录
        if (setting.takeToken()) {
            // 判断是否已经注册动态路由
            if (!store.state.user.menus) {
                // 获取动态路由
                store.dispatch('user/getMenus').then(({ menus, home }) => {
                    // menuToRoutes方法是把菜单数据转成路由数据格式
                    router.addRoute({
                        path: '/',
                        component: EleLayout,
                        redirect: setting.homePath || home,
                        children: menuToRoutes(menus, (component) => import('@/views' + component))
                    });
                    next({ ...to, replace: true });
                }).catch(() => {
                    next();
                });
            } else {
                next();
            }
        } else if (setting.whiteList.includes(to.path)) {
            next();
        } else {
            next({ path: '/login', query: to.path === '/' ? {} : { from: to.path } });
        }
    }

    if (process.env.NODE_ENV === 'production' && setting.version == '1.5.2') {
        updateLang(to).then(() => {
            handle();
        })
    } else {
        handle();
    }

});

router.afterEach(() => {
    setTimeout(() => {
        NProgress.done(true);
    }, 300);
});

export default router;

/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle(route) {
    if (!route.path.startsWith('/redirect/')) {
        let names = [];
        if (route && route.meta && route.meta.title) {
            names.push(route.meta.title);
        }
        const appName = setting.title;
        if (appName) {
            names.push(appName);
        }
        document.title = names.join(' - ');
    }
}

/**
 * 更新语言包
 * @param route 路由
 */ 
const updateLang = function () {
    return Promise.resolve();
    // return new Promise(resolve => {
    //     const lang = i18n.global.locale;
    //     let fetch_arr = [];

    //     /* 缓存全局 */
    //     if (!cache_lang[lang]) {
    //         fetch_arr.push(axios.get(`/getLang/${lang}.json`, { baseURL: process.env.VUE_APP_API_LANG_URL }));
    //     }
    //     if (!cache_lang['common']) {
    //         fetch_arr.push(axios.get(`/getLang/common.json`, { baseURL: process.env.VUE_APP_API_LANG_URL }));
    //     }
    //     if (!setting.whiteList.includes(route.path) && !cache_lang[route.path]) {

    //         if (route.path != "/admin/config/lang" && !route.path.startsWith("/user")) {
    //             fetch_arr.push(axios.get(`/getLang${route.path}.json`, {
    //                 baseURL: process.env.VUE_APP_API_LANG_URL
    //             }));
    //         }

    //     }

    //     if (fetch_arr.length == 0) {
    //         resolve();
    //         return
    //     }


    //     Promise.allSettled(fetch_arr).then(result => {

    //         if (result.length == 3) {
    //             cache_lang[lang] = result[0].value.data;
    //             cache_lang['common'] = result[1].value.data;
    //             result.forEach(item => {
    //                 if (item.status == 'fulfilled') {
    //                     Object.assign(i18n.global.messages[lang], item.value.data);
    //                 }
    //             })
    //         } else {
    //             result.forEach(item => {
    //                 if (item.status == 'fulfilled') {
    //                     Object.assign(i18n.global.messages[lang], cache_lang[lang], cache_lang['common'], item.value.data);
    //                 }
    //             })
    //         }
    //     }).catch(() => {
    //         // console.error(e);
    //     })
    //     resolve();
    // })
}
