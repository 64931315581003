
import zh_CN from 'ele-admin-pro/packages/lang/zh_CN';
import en from 'ele-admin-pro/packages/lang/en_US';

const languages = {
    zh_CN,
    en
};

export default {
    data() {
        return {
            locale: languages[this.$i18n.locale]
        };
    },
    computed: {
        language() {
            return this.$i18n.locale;
        }
    },
    watch: {
        language() {
            this.locale = languages[this.language];
        }
    }
};
